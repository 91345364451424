.products {
	#fox-container-m115 {
		display: none; }

	#main-component {
		margin: 40px 0 50px;

		.row.subcategories {
			margin-top: 40px; }


		.col-12.col-md-4 {
			margin-bottom: 30px;
			.cont {
				padding: 40px 0;
				background: #ebebeb;

				&:hover .cont-title a {
					color: $tcolor; }

				&-img {
					width: 300px;
					height: 300px;
					margin: 0 auto;
					background: #d3d3d3;
					border-radius: 50%;

					.flip-container {
						perspective: 1000px;
						transform-style: preserve-3d; }

					.flip-container:hover .back {
						transform: rotateY(0deg); }

					.flip-container:hover .front {
						transform: rotateY(180deg); }


					.flip-container, .front, .back {
						width: 300px;
						height: 300px; }

					.flipper {
						transition: 0.6s;
						transform-style: preserve-3d;
						position: relative; }

					.front, .back {
						backface-visibility: hidden;
						transition: 0.6s;
						transform-style: preserve-3d;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						overflow: hidden; }

					.front {
						z-index: 2;
						transform: rotateY(0deg); }

					.back {
						transform: rotateY(-180deg);
						a, a img {
							width: 100%;
							height: 100%;
							display: block; } } }




				&-title a {
					padding: 15px;
					display: block;
					text-align: center;
					color: $fcolor; } } } } }

.products {
	#bot-image {
		height: 30vh;
		.container-fluid {
			height: 100%;
			background: {
				image: url("../images/bg_products_bot.jpg");
				attachment: fixed;
				position: center;
				repeat: no-repeat;
				size: cover; } } }

	#category-info {
		min-height: 40vh;
		display: flex;
		align-items: center;
		background: {
			size: 100% auto !important;
			repeat: no-repeat !important;
			position: 50% 50%  !important; }
		h1 {
			margin-top: 20px;
			span {
				color: #fff;
				font-size: 36px;
				line-height: 37px;

				&:last-child {
					display: block; } } } }

	#products {
		.hikashop_category_information.hikashop_products_listing_main {
			width: 100%;
			.row {
				.col-12.col-md-4 {
					margin-bottom: 30px;
					&:hover {
						.product-image {
							border-color: $fcolor; }
						.product-title {
							background: $fcolor;
							* {
								color: #fff; }

							.product-name {
								color: $scolor; }

							.right form a span.icon {
								border-color: #fff;
								.fas {
									color: #fff; } } } } }

				.product-image {
					border: 1px solid $gcolor;
					@include transition-attr(border, .6s);
					img {
						display: block;
						margin: 0 auto; } }

				.product-title {
					height: 175px;
					padding: 20px;
					background: $gcolor;
					display: flex;
					align-items: center;
					@include transition-attr(background, .4s);

					* {
						color: $fcolor;
						@include transition-attr(color, .4s); }



					.left {
						width: 70%;
						.product-name {
							display: block;
							font-weight: bold; }

						a:not(.product-name) {
							font-size: 16px;
							line-height: 18px; }

						a:nth-child(2) {
							font-weight: 800; } }

					.right {
						width: 30%;
						form a {
							span {
								display: block;
								text-align: center;
								color: $tcolor;
								font-size: 14px;
								&.icon {
									width: 30px;
									height: 30px;
									display: flex;
									margin: 5px auto 0;
									justify-content: center;
									align-items: center;
									border: {
										radius: 50%;
										color: $tcolor;
										width: 2px;
										style: solid; }
									@include transition-attr(border, .4s);
									.fas {
										color: $tcolor;
										font-size: 11px;
										@include transition-attr(color, .4s); } } }

							&:hover span .fas {
								font-size: 15px; } } } } }

			.row.pagination {
				margin: 15px 0;
				form {
					.list-footer.pagination.pagination-toolbar.clearfix {
						display: flex;
						align-items: center;
						justify-content: center;
						a {
							padding: 0 5px;
							color: $textgray;
							font-weight: 400; }

						span,
						.pagenav_next_chevron {
							padding: 0 5px;
							color: $tcolor;
							font-weight: 800; } }
					.limit,
					.counter,
					.pagenav_end_chevron,
					.pagenav.pagenav_text,
					.pagenav_start_chevron,
					.hikashop_results_counter,
					.pagenav.hikashop_end_link,
					.pagenav.hikashop_next_link {
						display: none; } } } } } }

.products #top-image {
	height: 50vh;
	background: {
		image: url(../images/top_products.png);
		repeat: no-repeat;
		size: cover; } }


.applications #top-image {
	height: 50vh;
	background: {
		image: url(../images/top_applications.jpg);
		repeat: no-repeat;
		size: cover; } }



@media screen and (max-width: 992px) {
	.products #main-component .col-12.col-md-4 .cont-img {
		width: 150px;
		height: 150px;

		.back,
		.front,
		.flip-container {
			width: 150px;
			height: 150px; } } }
