.about,
.about-us {
	#video {
		height: 80vh;
		width: 100vw;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		.video-container {
			position: absolute;
			min-width: 100%;
			min-height: 100%;

			video {
				min-width: 100%;
				min-height: 100%; } }

		.intro-text {
			z-index: 2;
			position: relative;
			img {
				display: block;
				margin: 50px auto;
				cursor: pointer; }

			p, span, strong {
				margin: 0;
				text-align: center;
				color: #fff;
				font-size: 34px;
				line-height: 50px; }

			hr {
				border-color: $scolor; } } }


	#main-component {
		padding-top: 70px;

		.content {
			margin-bottom: 70px;
			& > div {
				margin-bottom: 40px;
				strong {
					display: block;
					margin-bottom: 15px;
					font-size: 18px;
					font-weight: bold; } } } }

	#milestones,
	#after-main {
		hr {
			margin-right: 0;
			border-color: $fcolor; } }

	#after-main {
		min-height: 50vh;
		padding: 100px 0;
		background: {
			color: $gcolor;
			image: url(../images/bg_about.jpg);
			repeat: no-repeat;
			size: 50% 100%;
			position: left bottom; }

		.section-title {
			margin-bottom: 0; }

		.col-12.col-md-6 {
			padding-left: 100px;

			p {
				margin: 25px 0 5px; }

			ul {
				padding-right: 15px;
				list-style: none;

				li {
					margin-bottom: 15px;
					position: relative;

					&:before {
						content: "•";
						color: $tcolor;
						font-size: 40px;
						line-height: 0;
						display: inline-block;
						position: absolute;
						top: 18px;
						right: -15px; } } } } }

	#milestones {
		padding: 100px 0;
		img {
			width: 100%;
			display: block;
			margin: 60px auto 0; } } }


.about.team {

	#main-component .content {
		margin-bottom: 20px; }

	#team .col-12.col-md-6.col-lg-3 {
		padding: 10px 15px;
		.flip-container {
			width: 100%;
			height: 250px;
			perspective: 1000px;
			transform-style: preserve-3d;
			cursor: pointer;

			&.show,
			&:hover {
				.back {
					transform: rotateY(0deg); }

				.front {
					transform: rotateY(180deg); } } }

		.flipper {
			transition: 0.6s;
			transform-style: preserve-3d;
			position: relative;

			.front,
			.back {
				width: 100%;
				height: 250px;
				backface-visibility: hidden;
				transition: 0.6s;
				transform-style: preserve-3d;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				&-title {
					width: 100%;
					padding: 15px;
					p, strong {
						width: 100%;
						text-align: center; }

					p {
						margin-bottom: 5px;
						&:first-of-type {
							font-size: 22px; } } } }


			.front {
				z-index: 2;
				transform: rotateY(0deg);

				img {
					width: 100%; }

				&-title {
					position: absolute;
					left: 0;
					bottom: 0;
					justify-content: center;
					align-items: center;
					display: flex;
					flex-wrap: wrap;
					background: rgba(0, 58, 98, 0.85);
					p, strong {
						color: #fff; } } }


			.back {
				transform: rotateY(-180deg);
				background: $scolor;
				justify-content: center;
				align-items: center;
				display: flex;

				hr {
					border-color: #fff; } } } }

	#testimonials {
		height: 50vh;
		margin: 80px 0 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background: {
			image: url(../images/bg_clients.jpg);
			repeat: no-repeat;
			position: center;
			size: cover; }
		.slick-slider {
			position: static;

			.slide p {
				margin: 0;
				font-weight: 600;
				text-align: center;
				font-size: 20px;
				line-height: 26px;

				&.author {
					margin-top: 70px;
					color: $textgray; } }

			.slick-arrow {
				padding: 15px;
				position: absolute;
				top: calc((50vh / 2) - 20px);
				background: $textgray;
				color: #fff;
				border: 0;
				cursor: pointer;
				@include transition-attr(all, .3s);
				&:hover {
					background: $fcolor; }

				&.slick-prev {
					left: 0; }

				&.slick-next {
					right: 0; } }


			.slick-dots {
				width: 100%;
				position: absolute;
				bottom: 50px;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: nowrap;
				list-style: none;
				li {
					button {
						width: 12px;
						height: 12px;
						margin: 0 2px;
						background: $textgray;
						border-radius: 50%;
						font-size: 0;
						line-height: 0;
						border: 0;
						cursor: pointer;
						@include transition-attr(all, .5s); }

					&.slick-active button {
						background: $fcolor; } } } } } }



@media screen and (max-width: 1200px) {
	.about.team #team .col-12.col-md-6.col-lg-3 .flipper .front-title {
		background: $fcolor; } }



@media screen and (max-width: 992px) {
	.about.team #team .col-12.col-md-6.col-lg-3 .flip-container {
		height: 360px;

		.flipper {
			.back,
			.front {
				height: 360px; } } } }


@media screen and (max-width: 767px) {
	.about,
	.about-us {
		#video .intro-text {
			p,
			span,
			strong {
				margin: 0;
				text-align: center;
				color: #fff;
				font-size: 20px;
				line-height: 26px; } }

		#main-component {
			padding-top: 20px;
			.content {
				margin-bottom: 0; } }

		#after-main {
			min-height: auto;
			padding: 0;
			background: {
				size: cover;
				position: 50% 50%; }
			.container {
				padding: 50px 15px;
				background: rgba(255, 255, 255, 0.75);

				.col-12.col-md-6 {
					padding-left: 15px; } } }
		#milestones {
			padding: 20px 0; } }

	.about.team {
		#testimonials {
			margin: 20px 0;
			.slick-slider {
				.slick-arrow {
					display: none !important; }

				.slide p {
					font-size: 17px !important;
					line-height: 20px !important; } } } } }


@media screen and (max-width: 400px) {
	.about.team {
		#team .col-12.col-md-6.col-lg-3 .flip-container {
			height: 315px;

			.flipper {
				.back,
				.front {
					height: 315px; } } }



		#testimonials {
			height: auto;
			padding: 50px 0;

			.slick-slider {
				.slide p.author {
					margin-top: 30px; }

				.slick-dots {
					bottom: 20px; } } } } }
