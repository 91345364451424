.projects {
	#projects-categ {
		padding: 40px 0;
		background: {
			image: url(../images/bg_clients.jpg);
			position: 50% 50%;
			size: cover; }

		.col-2 {
			padding: 10px;
			@include transition-attr(.5s,background);
			a {
				display: block;
				text-align: center;
				color: $fcolor;
				font-size: 16px;
				line-height: 18px;
				font-weight: 600; }

			.categ-imag {
				height: 100px; }


			&:hover,
			&.active {
				background: $scolor; } } }


	#main-component {
		margin: 40px 0 50px;
		.item {
			padding: 0;

			&-bott {
				height: 300px; }
			&-top {
				height: auto; //400px
				overflow: hidden;
				position: relative;
				.i-zoom {
					width: 140px;
					height: 140px;
					background: rgba(255, 203, 6, 0.7);
					border-radius: 50%;
					position: absolute;
					top: calc(50% - 70px);
					left: calc(50% - 70px);
					opacity: 0;
					@include transition-attr(opacity, .5s);
					a {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						.fas {
							color: #fff;
							font-size: 36px; } } }
				img {
					width: 100%; } }

			&-bott {
				padding: 40px;
				background: #ebebeb;

				strong {
					font-size: 20px; } }

			&:nth-child(3n+2) {

				.item-bott {
					background: $gcolor; } }

			&:hover {
				.item-bott * {
					color: $tcolor; }

				.item-top .i-zoom {
					opacity: 1; } } } }


	#main-component.project {
		padding: 40px 0;
		.row {
			&.main-image {
				margin-bottom: 15px; }

			&.partner {
				margin: 10px -15px 30px; }

			&.more-info {
				ul {
					list-style: none;
					padding-right: 10px;
					li:before {
						content: '⬤';
						margin: 0 -10px 0 5px;
						color: $tcolor;
						font-size: 9px;
						vertical-align: middle; } } }

			&.categories {
				margin-bottom: 20px;
				* {
					color: $bcolor; } }

			&.ask {
				margin-top: 30px;
				a {
					padding: 10px;
					display: block;
					background: $tcolor;
					color: #fff;
					text-align: center; } } } }

	#pagination .container ul {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		li {
			display: block;
			padding: 0 5px;
			&.pagination-start,
			&.pagination-end {
				display: none; }

			a,
			&.pagination-next *,
			&.pagination-prev * {
				color: $textgray;
				font-size: 18px; }

			span {
				color: $tcolor;
				font-size: 18px; } } }


	#projects-navigation {
		padding: 30px 0;
		background: $bcolor;
		.pager.pagenav {
			list-style: none;
			display: flex;
			align-items: center;
			li {
				width: 50%;
				a {
					color: #fff;
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					span {
						margin: 0 10px;
						color: #fff; }
					.fas {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						border: 1px solid #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff; }


					&:hover {
						&,
						& .fas {
							color: $scolor;
							border-color: $scolor; } } }

				&.previous a {
					justify-content: flex-start; }

				&.next a {
					justify-content: flex-end; } } } } }


//@media screen and (max-width: 1200px)
//	.projects #main-component .item-top
//		height: 320px


@media screen and (max-width: 992px) {
	.projects #projects-categ .col-2 .categ-imag {
		height: 75px; } }

@media screen and (max-width: 767px) {
	.projects #projects-categ .col-2 .categ-imag {
		height: 55px;
		a img {
			max-width: 50px; } } }

