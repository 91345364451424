.rtl {
	.row {
		direction: rtl; }

	p, a, h1, h2, h3, ul, li, div, span {
		text-align: right;
		&:not(.fas) {
			font-family: Heebo; } }

	.offset-md-5 {
		margin-left: 0;
		margin-right: 41.666667%; }

	header .container .row .menu-cont .nav.menu li.parent ul {
		left: auto;
		right: 0; }


	&.product #product .row.buttons .offset-lg-5 {
		margin: 0 41.666667% 0 0; }



	&.faq-he #faq .card-header {
		border-left: 0;
		border-right: 5px solid $tcolor; } }

