.my-plan {

	#plan {
		margin: 20px 0 50px;

		.logo-hidden {
			margin-bottom: 25px;
			padding: 20px 15px;
			background: $fcolor;
			display: none; }

		form {
			width: 100%;


			#hikashop_checkout {
				.prd {
					margin: 0 0 20px 0;
					display: flex;
					align-items: stretch;
					.row {
						margin: 0;
						align-items: stretch; }


					&-img,
					&-info {
						padding: 20px 15px;
						border: {
							width: 1px;
							style: solid;
							color: $gcolor; } }

					&-img {
						border-left: 0; }

					&-info {
						background: $gcolor;
						border-right: 0;

						a.delete.hikashop_no_print {
							width: 40px;
							height: 40px;
							position: absolute;
							left: 0;
							top: 0;
							background: $fcolor;
							z-index: 2;
							display: flex;
							align-items: center;
							justify-content: center;

							.fas {
								color: #fff; } }

						a:not(.prd-name) {
							font-size: 16px;
							color: $tcolor; }

						hr {
							margin-right: 0; }

						strong {
							color: $tcolor; }

						ul {
							padding-right: 25px; } }

					&-name {
						display: block;
						color: $fcolor;
						font-weight: bold;
						font-size: 17px; } }

				#go-login,
				#make-plan {
					margin-top: 50px;
					padding: 10px;
					background: $fcolor;
					border: 1px solid $fcolor;
					@include transition-attr(background, .5s);
					a {
						display: block;
						text-align: center;

						span {
							color: #fff; }
						.fas,
						.icon-plus {
							margin-right: 20px;
							color: #fff; } }


					&:hover {
						background: $scolor;
						a,
						.icon-plus {
							color: $fcolor; } } } } } }


	#after-plan {
		padding: 100px 0;
		margin-bottom: 50px;
		background: {
			image: url(../images/bg_plan.jpg);
			repeat: no-repeat;
			size: cover;
			position: bottom center; }

		* {
			color: #fff;
			text-align: center; }
		p {
			margin: 0;

			&:first-of-type {
				font-size: 34px; }
			&:last-of-type {
				font-size: 24px; } }

		hr {
			margin: 20px auto 40px;
			border-color: #fff; }

		img {
			margin: 30px 0; } } }
