body, html {
	overflow-x: hidden;
	p, a, h1, h2, h3, li, div, span {
		font-family: Dosis; } }

body.compensate-for-scrollbar {
	margin: 0 !important; }

img {
	max-width: 100%; }

p, li, div, a {
	&:not(strong),
	& *:not(strong) {
		font-size: 16px;
		line-height: 1.5;
		color: $fcolor;
		font-weight: normal; } }

hr {
	max-width: 90px;
	border-top: {
		width: 2px;
		style: solid; } }

ul {
	margin: 0;
	padding: 0;

	li {
		color: $fcolor; } }

.yellow {
	color: $scolor !important; }

.orange {
	color: $tcolor !important; }

.pseudo-select {
	height: 50px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;

	ul {
		width: 100%;
		max-height: 0;
		position: absolute;
		top: 50px;
		left: 0;
		background: #fff;
		z-index: 1;
		list-style: none;
		column-count: 2;
		overflow: hidden;
		opacity: 0;
		@include transition-attr(opacity, .5s);

		&.open {
			max-height: 1000px;
			opacity: 1; }

		li {
			padding: 5px 15px;
			font-size: 16px;
			color: $fcolor;
			@include transition-attr(all, .6s);
			a {
				display: block;
				font-size: 16px;
				color: $fcolor; }
			&:hover {
				background: $fcolor;
				color: #fff;
				a {
					color: #fff; } } } }

	p {
		margin: 0; }

	.fas {
		margin-right: 20px; } }

.b-acc_hide_toolbar.b-acc-icon-small {
	display: none; }

#slider .slick-slide {
	position: relative;

	&-text {
		width: 100%;
		height: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			margin: 0;
			color: #fff;
			font-size: 80px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			line-height: 1.3; } } }


#aside-close {
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 99999999;
	* {
		color: $scolor;
		font-size: 30px; }

	&.shw {
		display: flex;
		align-items: center;
		justify-content: center; } }

section .container-fluid .row.brands {
	height: 60px;
	background: $fcolor;
	position: relative;
	align-items: center;

	p {
		margin: 0;
		color: #fff;
		text-align: left;
		font-weight: bold; } }


section#services {
	padding: 100px 0;
	background: {
		image: url(../images/bg_services.jpg);
		position: 50% 50%;
		repeat: no-repeat;
		size: cover; }

	.section-title {
		margin-bottom: 40px;
		padding-right: 20px;

		p,
		.section-title-img {
			display: inline-block;
			vertical-align: middle; }

		.section-title-img {
			margin-right: 10px;
			padding-bottom: 15px;
			border-bottom: 2px solid #fff; }

		p {
			margin-bottom: 10px; } } }



section#projects .row {
	height: 45vh;
	direction: ltr;
	.project {
		background: {
			size: cover !important;
			repeat: no-repeat !important; } }
	.blank {
		display: flex;
		align-items: center;
		justify-content: center;
		p span {
			display: block;
			font-size: 40px;
			color: #fff;
			text-align: center;
			font-weight: 300;
			&:last-child {
				width: 30px;
				border-bottom: 2px solid #fff;
				margin: 20px auto 0; } } }



	.project {
		position: relative;

		.title {
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 20px;
			text-align: center;
			z-index: 2;
			a {
				width: 80%;
				padding: 10px;
				border: 2px solid #fff;
				display: inline-block;
				text-align: center;
				color: #fff;
				font-weight: 500;
				font-size: 21px;
				@include transition-attr(all, .5s); } }

		.overlay {
			width: 100%;
			height: 100%;
			opacity: .5;
			position: absolute;
			left: 0;
			@include transition-attr(all, .5s); }

		&:hover {
			.title a {
				background: #fff;
				color: $fcolor; }

			.overlay {
				opacity: 0; } }

		&.col-md-6 .title {
			width: 50%;
			left: auto;
			right: 0; } } }


#related-products {
	padding: 80px 0;
	background: $gcolor;
	.section-title {
		hr {
			margin-right: 0;
			border-color: $fcolor; } }

	.related-slider,
	.hikashop_products_listing {
		position: relative;
		.slick-list {
			.slide {
				padding: 0 15px;

				.rel-product-bot {
					padding: 15px 30px;
					background: #fff;
					border-top: 1px solid $tcolor;
					p {
						margin-bottom: 5px;
						direction: rtl;

						&:nth-child(2) * {
							font-size: 16px; } } } } }


		.slick-arrow {
			width: 30px;
			height: 80px;
			position: absolute;
			top: calc(50% - 40px);
			border: 0;
			background: $bcolor;
			z-index: 1;
			cursor: pointer;
			@include transition-attr(background, .5s);

			.fas {
				color: #fff;
				font-size: 28px; }

			&:hover {
				background: $scolor; }

			&.slick-prev {
				left: -15px; }

			&.slick-next {
				right: -15px; } } } }


.page-title {
	margin-bottom: 20px;
	h1 {
		font-size: 40px;
		line-height: 40px;
		font-weight: 300;
		margin: 0;
		color: $tcolor; }

	hr {
		margin: 15px 0 15px auto;
		border-color: $fcolor; } }


.section-title {
	margin-bottom: 50px;
	* {
		display: inline-block;
		vertical-align: middle; }
	p {
		margin: 0;
		font-size: 40px !important;
		color: $tcolor;
		font-weight: 300;
		line-height: 40px; }

	hr {
		margin: 20px auto;
		display: block;
		border-color: $gcolor; } }


header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: $fcolor;
	z-index: 3;

	.container-fluid {
		height: 2px;

		background: rgb(244,118,34);
		background: -moz-linear-gradient(left, rgba(244,118,34,1) 0%, rgba(255,195,13,1) 12%, rgba(255,253,241,1) 25%, rgba(0,132,193,1) 37%, rgba(244,118,34,1) 50%, rgba(255,195,13,1) 62%, rgba(255,255,255,1) 73%, rgba(0,132,193,1) 85%, rgba(244,118,34,1) 100%);
		background: -webkit-linear-gradient(left, rgba(244,118,34,1) 0%,rgba(255,195,13,1) 12%,rgba(255,253,241,1) 25%,rgba(0,132,193,1) 37%,rgba(244,118,34,1) 50%,rgba(255,195,13,1) 62%,rgba(255,255,255,1) 73%,rgba(0,132,193,1) 85%,rgba(244,118,34,1) 100%);
		background: linear-gradient(to right, rgba(244,118,34,1) 0%,rgba(255,195,13,1) 12%,rgba(255,253,241,1) 25%,rgba(0,132,193,1) 37%,rgba(244,118,34,1) 50%,rgba(255,195,13,1) 62%,rgba(255,255,255,1) 73%,rgba(0,132,193,1) 85%,rgba(244,118,34,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f47622', endColorstr='#f47622',GradientType=1 ); }

	.container {
		max-width: 1400px;
		padding-top: 20px;

		.row {
			align-items: flex-end;

			.logo {
				max-width: 380px;
				padding-bottom: 15px;
				img {
					max-width: 100%; } }

			.menu-cont {
				max-width: calc(100% - 380px);
				position: static;
				.mob-t {
					display: none;
					background: $fcolor;
					height: 75px;
					width: 75px;
					align-items: center;
					justify-content: center;
					font-size: 30px;
					float: left;
					.fas {
						color: #fff;
						font-size: 44px; } }

				.nav.menu {
					& > li {
						display: inline-block;
						padding-bottom: 15px;
						margin-bottom: -5px;
						& > a {
							font-size: 20px;
							font-weight: 400; } }

					li {
						a, .fa-search {
							display: block;
							padding: 0 10px;
							color: #fff;
							text-transform: uppercase; }

						img {
							display: none !important; }

						.fas {
							cursor: pointer; }

						&.item-110 a,
						&.item-119 a {
							color: $tcolor; } }




					li.active a {
						color: $scolor; }

					& > li.parent {
						position: relative;
						& > ul {
							max-height: 0;
							min-width: 300px;
							position: absolute;
							top: 42px;
							left: 0;
							z-index: 2;
							opacity: 0;
							background: #fff;
							overflow: hidden;
							@include transition-attr(max-height, .5s);
							@include transition-attr(opacity, .5s);

							li {
								width: 100%;
								text-align: left;
								a {
									padding: 5px 20px;
									color: $textgray;
									display: block; }

								&:hover {
									& > a,
									& > a span {
										color: $fcolor;
										font-weight: 600; } } } }


						&:hover > ul {
							max-height: 1000px;
							opacity: 1;
							padding: {
								top: 20px;
								bottom: 20px; } } }

					li.item-105 {
						position: static;
						& > ul {
							width: 100%;
							top: 100px;
							left: 0;
							display: flex;
							position: absolute;
							padding: {
								left: calc((100% - 1400px) / 2);
								right: calc((100% - 1400px) / 2); }
							& > li {
								width: 33%;
								display: inline-block;
								&:nth-child(2) {
									border: {
										left: 1px solid $gcolor;
										right: 1px solid $gcolor; } }

								& > a {
									font-weight: bold; }

								ul {
									align-items: stretch;
									list-style: none; }

								&.item-172 ul,
								&.item-173 ul {
									column-count: 2; } } } } } } } } }

header + section {
	margin-top: 95px; }

#top-search .container-fluid .row .col-12 {
	position: relative;
	.search {
		position: absolute;
		top: 5px;
		z-index: 1;
		display: none;
		form.form-inline {
			padding: 7px;
			background: #fff;

			label {
				display: none; }

			#mod-search-searchword107 {
				padding: 5px 15px;
				border: 0;
				border-bottom: 2px solid $gcolor; } } } }


#breadcrumbs {
	padding: 10px 0;
	* {
		font-size: 14px;
		font-weight: 400;
		color: $textgray; }

	span.divider {
		margin-left: 4px; } }


footer {
	padding: 45px 0;
	background: {
		image: url(../images/bg_footer.jpg);
		color: $gcolor;
		repeat: no-repeat;
		position: bottom;
		size: cover; }

	* {
		color: $fcolor; }

	.footer-menu {
		padding-top: 30px;
		.col-12.col-md-4 {
			& > p {

				margin-bottom: 8px; }
			& > p,
			& >p >a {
				font-size: 18px;
				font-weight: bold; }

			ul {
				list-style: none;
				li a {
					font-size: 16px;
					line-height: 20px; } } } } }

#applications {
	padding: 60px 0;
	background: $gcolor;

	.section-title {
		p,
		&-img {
			display: inline-block;
			margin: 0;
			vertical-align: middle; }

		&-img {
			margin-left: 10px; }

		hr {
			margin-right: 0;
			border-color: $fcolor; } }

	.application {
		margin-bottom: 50px;
		text-align: center;
		p {
			margin: 0;
			text-align: center; }

		.img-cont {
			width: 150px;
			height: 150px;
			margin: 0 auto;
			margin-bottom: 15px;
			background: #d3d3d3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			overflow: hidden;
			position: relative;

			img:nth-child(2) {
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 50%;
				opacity: 0;
				@include transition-attr(all, .8s); } }

		&:hover .img-cont img:nth-child(2) {
			opacity: 1; } } }


#after-footer {
	padding: 15px 0;
	background: $fcolor;
	* {
		color: #fff;
		font-size: 16px;
		line-height: 16px; }

	.col-12.col-md-6 {
		&:last-child {
			text-align: left; } } }


#fox-container-m118 .fox-column6,
#fox-container-m106 .fox-column6,
#fox-container-m117 .fox-column6,
#fox-container-m112 .fox-column6,
#fox-container-m121 .fox-column6, {
	width: 50% !important;
	margin: 0 !important;



	.fox-item {
		margin: 0;
		.controls {
			width: 100% !important;
			padding: 5px;
			input,
			textarea {
				width: 100%;
				padding: 5px;
				border-radius: 0;
				background: $gcolor;
				font-size: 18px;
				border: 1px solid transparent;

				&:hover {
					background: rgba(0, 58, 98, 0.21);
					border: 1px solid $scolor; } }

			input {
				height: 30px;
				padding: 25px 10px; }


			button.btn.btn-primary.submit-button {
				width: 100%;
				margin: 0;
				padding: 10px 0;
				border: 0;
				border-radius: 0;
				text-shadow: none;
				text-transform: uppercase; } } } }



#fox-container-m118,
#fox-container-m106 {
	.fox-column6 {
		&:first-child .control-group .controls {
			padding-bottom: 0; }

		.fox-item .controls {
			textarea {
				height: 175px !important; }

			button.btn.btn-primary.submit-button {
				background: $scolor;
				span {
					color: $fcolor; }

				&:hover {
					background: $fcolor;
					span {
						color: $scolor; } } } } } }

.hikashop_badge_topright_div {
	z-index: 1 !important; }

#fox-container-m117,
#fox-container-m112,
#fox-container-m121 {
	.fox-form .fox-row .fox-column .control-group .controls {
		button.btn.btn-primary.submit-button {
			background: $fcolor;
			span {
				color: $scolor; }

			&:hover {
				background: $scolor;
				span {
					color: $fcolor; } } } } }


#fox-container-m89,
#fox-container-m117,
#fox-container-m112,
#fox-container-m121 {
	.fox-form .fox-row {
		min-height: 0;

		.fox-column {
			margin: 0;
			min-height: 0;

			&.fox-column3,
			&.fox-column6 {
				margin: 0;
				float: left; }

			&.fox-column3 {
				width: 25%;
				padding-right: 5px; }

			&.fox-column6 {
				width: 50%; }


			.control-group {
				margin-bottom: 5px;
				.controls {
					width: 100%;
					select,
					textarea,
					input[type="text"],
					input[type="email"] {
						width: 100% !important;
						height: 50px;
						padding: 15px;
						border-radius: 0;
						border: 0; }

					button.btn.btn-primary.submit-button {
						width: 100%;
						height: 50px;
						margin-top: -6px;
						border-radius: 0;
						text-shadow: none;
						border: 0;
						background: {
							color: $fcolor;
							image: none; }

						span {
							color: #fff;
							font-size: 30px; } } } } } } }


aside {
	position: fixed;
	left: 0;
	bottom: 30vh;
	z-index: 2;
	.menu {
		position: relative;
		.toggle {
			position: absolute;
			width: 33px;
			height: 100%;
			right: -33px;
			top: 0;
			display: none;
			.cont {
				width: 100%;
				height: 100%;
				margin-left: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $tcolor;
				cursor: pointer;
				.fas {
					color: #fff; } } }

		&:hover .toggle {
			display: block; }

		ul li {
			width: auto;
			height: 40px;
			margin-bottom: 3px;
			background: $gcolor;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@include transition-attr(background, .3s);

			.count {
				display: inline-block; }

			a {
				display: block;
				padding-right: 25px;
				text-align: left;
				color: $fcolor;
				@include transition-attr(color, .3s);

				span {
					font-size: 20px;
					font-weight: normal; }

				i {
					width: 40px;
					margin-right: 10px;
					display: inline-block;
					text-align: center;
					font-size: 20px;
					color: $fcolor;
					@include transition-attr(color, .3s); } }


			&.hid {
				width: 100%;
				a {
					text-align: center;
					padding: 0;
					i {
						margin-right: 0; }

					span {
						display: none; } } }


			&:first-child {
				background: $tcolor;
				* {
					color: #fff; } }
			&:hover {
				background: $fcolor;
				a,i {
					&,
					& span {
						color: $scolor; } } } } }

	.social {
		margin-bottom: 5px;
		ul li {
			width: 40px;
			height: 40px;
			background: #003a62;
			margin-bottom: 3px;
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				display: block;
				.fab {
					background: $fcolor;
					color: #fff;
					@include transition-attr(all, .3s); } }

			&:hover a .fab {
				color: $scolor; } } } }



////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1600px) {

	header {
		& + section {
			margin-top: 65px; }


		.container .row {
			.logo {
				max-width: 270px; }
			.menu-cont {
				max-width: none;
				flex: 0 0 calc(100% - 270px); } } } }



@media screen and (max-width: 1200px) {
	header .container {
		padding-top: 0;
		.row {

			.logo img {
				max-width: 210px; }

			.menu-cont {
				position: static;
				padding: 0;

				.mob-t {
					display: flex; }


				.nav.menu {
					width: 100%;
					max-height: 0;
					padding: 0;
					position: absolute;
					top: 75px;
					left: 0;
					background: rgba(155, 158, 163, 0.8);
					z-index: 2;
					overflow: hidden;
					border-bottom: 0;
					@include transition-attr(all,.5s);


					&.show {
						padding: 30px 0 30px;
						max-height: 1000px;
						opacity: 1;
						border-bottom: 4px solid $fcolor; }

					li {
						width: 100% !important;
						border: 0 !important;
						padding: 3px !important;
						a {
							padding: 5px;
							border: 0;
							text-align: center !important;
							background: #fff !important;
							color: #000 !important; }


						&.item-120 {
							display: none; } }


					& > li:last-child {
						padding-bottom: 15px; }

					& > li {
						margin: 0 auto;
						max-width: 300px;
						display: block; }

					li,
					li:hover {
						ul {
							display: none !important; } }

					li.parent.opn {
						& > a {
							background: $fcolor !important;
							color: #fff !important; }


						ul {
							display: block !important;
							position: relative;
							padding-top: 0 !important;
							top: 0;
							background: transparent;
							max-height: 500px;
							opacity: 1;
							margin-top: 5px;
							min-width: auto;

							li {
								margin-bottom: 3px;
								padding-left: 0;
								padding-right: 0;

								a {
									padding: inherit !important; } } } } } } } }


	footer {
		background-image: none;
		.footer-menu {
			flex: 0 0 100%;
			max-width: 100%; }

		.footer-form {
			display: none; }

		.container-fluid .row > div {
			margin-bottom: 5px;

			&.foot-logo {
				order: 1; }
			&.info-address {
				order: 3; }
			&.info-rights {
				order: 2; }

			&.theguy {
				order: 4; } } } }

@media screen and (max-width: 767px) {
	.pseudo-select ul {
		column-count: 1; }

	p {
		text-align: justify; }

	.page-title h1,
	.section-title p {
		font-size: 32px !important;
		line-height: 32px !important;
		text-align: right !important; }

	#related-products {
		padding: 35px 0;
		.hikashop_products_listing,
		.related-slider {
			.slick-arrow {
				display: none !important; }

			.slick-list .slide {
				padding: 0; } } }


	#fox-container-m89,
	#fox-container-m112,
	#fox-container-m121,
	#fox-container-m117 {
		.fox-form .fox-row .fox-column {
			&.fox-column3,
			&.fox-column6 {
				width: 100% !important;
				padding: 0; } } }

	#fox-container-m112 .fox-form .fox-row,
	#fox-container-m121 .fox-form .fox-row, {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column-reverse; }

	aside {
		bottom: 0; }

	#services {
		display: none; }

	header .container .row .menu-cont {
		max-width: none; }

	section .container-fluid .row.brands {
		height: auto;
		padding: 15px 0;
		margin-top: 0 !important;
		p {
			margin: 10px 0;
			text-align: center; } }

	section#projects .row {
		height: auto;
		.blank {
			order: 1; }
		.project {
			height: 200px;
			order: 2; }

		.col-md-6 .title {
			width: 100% !important; } }


	footer {
		padding: 0;
		.footer-menu .col-12.col-md-4 {
			p {
				text-align: center;
				border-bottom: 1px solid $fcolor; }

			ul li {
				width: 100%;
				padding: 5px;
				margin-bottom: 10px;
				text-align: center;
				background: #cecece; } }

		.footer-form {
			display: none; } }

	#after-footer .col-12.col-md-6 {
		margin-bottom: 15px;
		text-align: center;

		&:last-child {
			text-align: center; } }


	#slider .slick-slide-text p {
		font-size: 40px; } }


@media screen and (max-width: 570px) {
	header {
		padding: 0;
		.logo {
			padding: 0; } } }

@media screen and (max-width: 450px) {
	h3 {
		text-align: center; }

	aside {
		display: none; }

	header .container .row {
		.logo {
			padding-bottom: 5px; }

		.menu-cont {
			.nav.menu {
				top: 50px; }

			.mob-t {
				height: 50px;
				width: 50px;

				.fas {
					font-size: 36px; } } } }

	header+section {
		margin-top: 50px; }

	#slider .slick-slide-text p {
		font-size: 24px; } }
