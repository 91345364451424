.request-catalog-wrapper {
  position: relative;

  .request-catalog-button {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url('../images/request-catalog/text.png'), url('../images/request-catalog/Mockup.png'), url('../images/request-catalog/button.png'), url('../images/request-catalog/bg.png');
    background-repeat: no-repeat;
    background-position: 96% 20%, 0 0, 86% 92%, 0 0;
    display: block;

    @include respond(1200) {
      height: 630px;
      background-position: 50% 74%, 50% 0, 50% 96%, 0 0;
      background-size: auto, auto, auto, cover;
    }

    @include respond(768) {
      height: 480px;
      background-position: 50% 69%, 50% 0, 50% 96%, 0 0;
      background-size: 80%, 100%, auto, cover;
    }

    @include respond(468) {
      height: 380px;
      background-position: 50% 65%, 50% 0, 50% 96%, 0 0;
      background-size: 80%, 100%, 80%, cover;
    }
  }

  .fox-container {
    display: none;

    .fox-form .fox-row .fox-column .control-group .controls button.btn.btn-primary.submit-button span {
      line-height: 32px;
    }

    @include respond(768) {
      .fox-form .fox-row {
        flex-direction: column !important;
      }
    }
  }
}