#search-result {
	min-height: 70vh;
	padding: 30px 0 50px;

	.badge-info {
		background-color: $tcolor; }

	.btn-toolbar {
		margin-bottom: 30px;

		#search-searchword {
			border: 1px solid $fcolor;
			padding: 5px 15px; }

		.btn.hasTooltip {
			background: $fcolor;
			color: #fff;
			border-radius: 0;

			.icon-search {
				color: #fff; } } }


	.result-title {
		padding: 15px 0 10px;
		border-top: 1px solid #dddddd;
		font-weight: 800;
		a {
			border-bottom: 1px solid $fcolor; } } }

