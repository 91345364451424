.partners {
	#main-component {
		padding: 40px 0 50px;
		.content {
			margin-bottom: 50px; }

		.partner {
			padding: 40px 60px;
			background: $gcolor;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			justify-content: center;

			&-logo {
				width: 200px;

				&-cont {
					width: 200px;
					height: 200px;
					margin: 0 auto;
					border-radius: 50%;
					background: #fff;
					display: flex;
					align-items: center;
					justify-content: center; }
				a {
					width: 150px;
					padding: 8px;
					margin: 20px auto 0;
					display: block;
					text-align: center;
					background: $tcolor;
					border-radius: 5px;
					* {
						color: #fff; } } }


			&-info {
				width: calc(100% - 200px);
				padding-right: 50px;

				p strong {
					font: {
						size: 18px;
						weight: bold; } } } } }

	#bot-image {
		height: 30vh;
		.container-fluid {
			height: 100%;
			background: {
				image: url("../images/bg_products_bot.jpg");
				attachment: fixed;
				position: center;
				repeat: no-repeat;
				size: cover; } } } }


.contact-us {
	#main-component {
		padding: 70px 0;
		.row.content {
			margin-bottom: 25px;

			p {
				margin-bottom: 0;
				strong {
					font-size: 18px;
					font-weight: bold; } } }

		#fox-container-m112 .fox-form .fox-row .fox-column .control-group .controls {
			input {
				border-bottom: 1px solid #565656; }

			button.btn.btn-primary.submit-button {
				height: 50px;
				margin-top: 15px;
				background: $scolor;
				span {
					color: $fcolor;
					font-size: 18px;
					font-weight: bold; } } } }



	#address {
		height: 50vh;
		display: flex;
		align-items: center;
		background: {
			image: url(../images/bg_contactus.jpg);
			size: cover; }

		.row {
			padding: 5px;
			border: 3px solid #e4e8eb;
			& > div {
				padding: 40px 20px;
				background: #e4e8eb;
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				.icon {
					flex: 0 0 65px;
					height: 65px;
					margin-left: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: {
						color: $tcolor;
						width: 2px;
						radius: 50%;
						style: solid; }

					div {
						width: 55px;
						height: 55px;
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: {
							color: $scolor;
							width: 2px;
							radius: 50%;
							style: solid; }

						img {
							max-width: 25px; } } }

				.text p {
					margin-bottom: 5px;
					&:first-child {
						font-weight: bold; } } } } }
	#map {
		width: 100vw;
		height: 50vh;

		&-cont {
			height: 50vh; } } }


.faq {
	margin-top: 35px;
	#faq {
		.card-header {
			background: $fcolor;
			color: #fff;
			border-left: 5px solid $tcolor;
			.btn {
				display: block;
				width: 100%; } } } }


.downloads {
	#faq {
		margin-bottom: 30px;

		.row.align-items-center {
			padding: 20px 0;
			border-bottom: 1px solid $fcolor;

			a.btn {
				width: 100%;
				background: $fcolor;
				text-align: center;
				border-radius: 0;
				* {
					color: #fff; }


				&:hover {
					background: $tcolor; } } } } }

@media screen and (max-width: 768px) {
	.contact-us {
		#main-component {
			padding: 20px 0;
			#fox-container-m106 .fox-row {
				display: flex;
				flex-wrap: wrap;
				.fox-column6 {
					flex: 0 0 100%;
					&:first-child {
						order: 2; }
					&:last-child {
						order: 1; } } } }

		#address {
			height: auto;
			padding: 30px 0; } }

	.partners #main-component {
		padding: 20px 0;
		.partner-logo {
			padding: 40px 15px; }

		.partner-info {
			padding-right: 15px;
			p {
				text-align: center; } } } }

@media screen and (max-width: 500px) {
	.partners #main-component .partner {
		padding: 0; } }

@media screen and (max-width: 400px) {
	.contact-us #address .row>div {
		padding: 15px;
		flex-wrap: wrap;
		.icon {
			margin: 0 auto 10px; }
		.text {
			width: 100%;
			* {
				text-align: center; } } } }
