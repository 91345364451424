body.ltr,
html body.ltr {
	a, div,	h1,	h2, h3, li, p, span {
		font-family: Heebo; }

	a, p, span {
		font-size: 16px; } }

.ltr {
	#breadcrumbs span.divider {
		margin: 0 4px 0 0; }


	header .container {
		max-width: 1500px;

		.row {
			.menu-cont {
				flex: 0 0 calc(100% - 380px);
				max-width: calc(100% - 380px);

				.nav.menu>li>a {
					padding: 0 6px;
					font-size: 16px;
					text-transform: none; } }

			.logo {
				flex: 0 0 380px;
				max-width: 380px; } } }

	aside {
		right: 0;
		left: auto;

		.menu ul li:first-child {
			background: #ddd;
			* {
				color: $fcolor; } } }

	.page-title,
	.section-title {
		hr {
			margin: 20px auto 20px 0; }

		p {
			line-height: 1.4;
			text-transform: uppercase; }

		h1 {
			text-transform: uppercase; } }


	#fox-container-m89,
	#fox-container-m112,
	#fox-container-m117 {
		.fox-form .fox-row .fox-column .control-group .controls button.btn.btn-primary.submit-button span {
			line-height: 1;
			font-size: 26px; } }


	&.home {
		#main-component .container .row {
			.col-12.col-md-6 {
				&:first-child img {
					right: 0;
					left: auto; } } }


		#products {
			padding: 10vh 0;
			background: #f4f4f4;

			hr {
				margin: 10px auto 0 0;
				border-top-color: $fcolor; }

			.row:nth-child(2) {
				margin: 0 -5px; }

			.product {
				padding: 0 5px;

				&-image {
					height: 200px;
					opacity: .75;
					@include transition-attr(all, .5s);
					img {
						width: 100%;
						height: 100%;
						object-fit: cover; } }

				&-text {
					height: calc(100% - 200px);
					padding: 20px 20px 30px;
					background: #fff;
					p {
						margin: 0;
						cursor: default; }

					a,
					a strong {
						font-size: 16px; }

					a strong {
						display: block;
						margin-bottom: 10px; }

					strong {
						text-transform: uppercase; } }

				&-container {
					height: 100%;
					background: #000;
					@include transition-attr(all, .3s); }

				&:hover {
					.product-image {
						opacity: 1; }
					.product-container {
						box-shadow: 0 0 15px #adadad; } } } } }


	&.contact-us #address .row>div .icon {
		margin-left: 0;
		margin-right: 15px; }

	&.about-us {
		section .container-fluid .row.brands p {
			text-align: right;
			text-transform: uppercase; }

		#after-main {
			background-position: 100% 0;

			.col-12.col-md-6 {
				padding: 0 100px 0 15px; } } } }


@media screen and (max-width: 1200px) {
	.ltr header .container .row .menu-cont .mob-t {
		float: right; } }

@media screen and (max-width: 992px) {
	.ltr {
		&.home #products .product {
			margin-bottom: 10px; }

		&.about-us section .container-fluid .row.brands {
			p {
				text-align: center; }
			img {
				display: block;
				margin: 0 auto; } } } }


@media screen and (max-width: 767px) {
	.ltr {
		.page-title h1,
		.section-title p {
			text-align: left !important; }

		&.home #products {
			padding: 15px 0;

			.section-title {
				margin-bottom: 20px; }

			hr {
				margin-top: 0; } }

		&.about-us #after-main .col-12.col-md-6 {
			padding-right: 15px; } } }

@media screen and (max-width: 400px) {
	.ltr.contact-us #address .row>div .icon {
		margin: 0 auto 20px; } }
