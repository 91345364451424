.home {
	a,
	div,
	li,
	p {
		&, & * {
			font-size: 18px; } }

	#main-component {
		background: {
			image: url(../images/bg_clients.jpg);
			color: $gcolor;
			repeat: no-repeat;
			position: bottom;
			size: cover; }

		.container .row {
			align-items: stretch;

			.section-title {
				margin: 25px 0; }

			.col-12.col-md-6 {
				&:first-child {
					position: relative;

					img {
						max-width: none;
						height: 100%;
						position: absolute;
						left: 0; } }

				&:last-of-type {
					padding: 120px 15px; }

				.orange {
					margin: 0;
					text-align: left; }

				hr {
					margin-right: 0;
					border-color: $fcolor; } } }

		.container-fluid .row.brands {
			margin-top: -60px;
			background: rgba(0, 58, 98, 0.85); } }


	#quick-search {
		padding: 15px;
		background: $fcolor;

		.col-12.col-md-4 {

			&:nth-child(1) {
				color: $tcolor;
				font-size: 40px;
				display: flex;
				align-items: center;
				justify-content: flex-end; }


			.pseudo-select {
				background: #fff;

				ul {
					column-count: 1; }


				.fa-caret-up {
					color: $tcolor; }

				input {
					border: 0; } }

			&:nth-child(3) .pseudo-select {
				p .trg {
					width: 140px;
					display: inline-block; }

				i {
					position: absolute;
					left: 55px; } } } }


	#news-events {
		padding: 60px 0;

		.cont-title {
			padding-bottom: 30px;
			h3 {
				color: $tcolor;
				font-size: 40px;
				font-weight: 300; }
			hr {
				margin: 0;
				border-top: 2px solid $fcolor; } }

		.cont-img {
			height: 300px;
			overflow: hidden;
			position: relative;
			img {
				width: 100%;
				position: absolute;
				left: 0;
				top: 0; }

			.bdg {
				width: auto;
				right: 0;
				z-index: 2; } }

		.news {
			padding: 0 15px 0 20px;
			.cont-text {
				.date {
					margin-bottom: 0;
					color: $textgray;
					font-size: 18px; }

				.fcolor {
					display: block;
					margin: 20px 0; }

				.fcolor,
				p:not(.date) {
					color: $fcolor; }

				hr {
					margin: 30px 0;
					border-top: 3px solid $gcolor; } } }

		.new-product {
			padding: 0 20px;
			.cont-text {
				p,
				strong {
					margin: 0;
					color: $fcolor; }

				a {
					display: block;
					margin-bottom: 10px;
					color: $textgray;
					font-weight: 400; } } }

		.events {
			padding: 0 15px 0 20px;
			& > div {
				height: 100%;
				background: $gcolor;

				.cont-title {
					background: #fff; }


				.cont-text {
					width: 80%;
					padding: 20px;
					margin: -86px auto 0;
					position: relative;
					background: #fff;
					z-index: 2;

					p {
						color: $fcolor;
						text-align: center;

						&:nth-of-type(1) {
							margin-bottom: 15px; }

						&:nth-of-type(2),
						&:nth-of-type(3) {
							margin-bottom: 3px;
							font-size: 18px;
							font-weight: bold; } } } } } }


	#clients {
		padding: 100px 0;
		background: {
			image: url(../images/bg_clients.jpg);
			position: 50% 50%; }

		.section-title {
			p {
				display: block;
				text-align: center; }

			hr {
				margin: 20px auto 0;
				border-color: $gcolor; } }
		.text {
			margin-bottom: 60px;
			p {
				margin-bottom: 5px;
				text-align: center;
				&,
				& span {
					font-size: 18px; } } }
		.col-md-125 {
			display: flex;
			align-items: center;
			justify-content: center; } } }




//////////////////////////////////////////////////////////////////////////////////
@media screen and (min-width: 993px) {
	.home #applications .application {
		flex: 0 0 20%;
		max-width: 20%; } }

@media screen and (min-width: 768px) {
	#clients .col-md-125 {
		max-width: 12.5%; }

	#services .col-md-20 {
		margin: 25px 0;
		max-width: 20%;

		.service {
			width: 140px;
			height: 140px;
			background: #fff;
			margin: 0 auto;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center; }
		p {
			margin: 15px 0 0;
			text-align: center;
			color: #fff; } } }


@media screen and (max-width: 767px) {
	.home {
		#main-component .container .row .col-12.col-md-6 {
			&:first-child img {
				width: 100%;
				position: relative; }


			&:last-of-type {
				padding: 0 15px 40px; } }

		#quick-search {
			.col-12.col-md-4 {
				margin-bottom: 15px;
				&:nth-child(1) {
					display: block;
					text-align: center;
					line-height: 1;
					font-size: 28px; } } }

		#news-events {
			padding: 0;
			.news,
			.events,
			.new-product {
				padding: 40px 15px;
				.cont-title {
					background: transparent; } } }


		#applications .application .img-cont {
			width: 105px;
			height: 105px; }


		#clients {
			padding: 30px 0;
			.col-md-125 {
				margin-bottom: 30px;
				text-align: center; } } } }
