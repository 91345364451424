.user-area {
	#main-component,
	.resetuser-area,
	.reminduser-area {
		min-height: 60vh;
		display: flex;
		padding-top: 30px;
		align-items: center;
		justify-content: center;

		.btn {
			width: 100%;
			border: 0;
			border-radius: 0;
			background: $gcolor;
			text-align: center;

			&.btn-primary {
				margin-bottom: 10px;
				background: $tcolor; } } }



	#main-component.login,
	#main-component.registration,
	#main-component.profile-edit,
	#user-registration {
		fieldset {
			.control-group {
				margin-bottom: 15px;

				label {
					margin: 0; }

				input {
					width: 100%;
					padding: 5px;
					border: 1px solid $fcolor;
					text-align: center; } } }

		ul.nav.nav-tabs.nav-stacked {
			padding: 0;
			list-style: none;

			li {
				text-align: center; } } }

	#user-registration {
		max-width: 400px; }

	#fox-container-m112 {
		display: none; }

	#main-component.profile-view {
		fieldset#users-profile-core {
			padding: 20px;
			background: $gcolor; } } }
