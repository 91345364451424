//Session Popup
#sessionModal {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    background: rgba(0, 0, 0, .59);
    width: 100vw;
    height: 100vh;
    display: none;

    @media (max-width: 768px) {
        overflow: overlay;
    }

    >.container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 969px;
        background-color: #fff;
        padding: 60px;
        color: #302f36;

        @media (max-width: 768px) {
            top: 0;
            left: auto;
            transform: none;
            padding: 20px;
        }

        .row {
            position: relative;

            .col-md-12 {
                text-align: justify;
                color: #302f35;
                font-size: 16px;
                font-weight: 300;

                h4 {
                    font-size: 28px;
                    font-weight: 300;
                    margin-bottom: 30px;
                    padding-bottom: 28px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-color: #e5e5e5;
                        width: 100%;
                        height: 2px;
                    }

                    strong,
                    b {
                        color: $fcolor;
                        font-weight: 900;
                        display: block;
                    }
                }

                a {
                    text-decoration: underline;
                }
            }
        }

        .btn {
            display: block;
            margin-top: 40px;
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            background-color: $fcolor !important;
            border-radius: 0;
            box-shadow: none;
            width: 100%;
            padding: 11px 10px;
        }
    }
}