.blog #main-component {
	.art-img-cont {
		max-width: 200px;
		border-radius: 50%;
		overflow: hidden;
		border: 3px solid $tcolor;
		margin: 0 0 30px; }

	.item {
		padding: 40px 60px;
		background: $gcolor;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: center;

		&-logo {
			width: 200px;
			padding: 15px;
			margin-bottom: 30px;
			background: $gcolor;

			&-cont {
				width: 200px;
				height: 200px;
				margin: 0 auto;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%; } }

			a {
				width: 150px;
				padding: 8px;
				margin: 20px auto 0;
				display: block;
				text-align: center;
				background: $tcolor;
				border-radius: 5px;
				* {
					color: #fff; } } }


		&-info {
			width: calc(100% - 200px);
			padding: 15px;
			margin-bottom: 30px;
			background: $gcolor;

			p strong {
				font: {
					size: 18px;
					weight: bold; } } } } }


@media screen and (max-width: 767px) {
	.blog #main-component .item-logo {
		margin-bottom: 0; } }
