.product .wrapper {
	background: {
		image: url(../images/bg_product.png);
		repeat: no-repeat; }


	#product {
		padding: 70px 0;

		.row.manufacturer {
			padding: 20px 0; }

		.row.more ul {
			list-style: none;
			padding-right: 10px;
			li:before {
				content: '⬤';
				margin: 0 -10px 0 5px;
				color: $tcolor;
				font-size: 9px;
				vertical-align: middle; } }


		.row.main-image {
			a {
				display: inline-block;
				margin-bottom: 15px;
				border: 1px solid $gcolor; } }


		.row.links {
			margin: 0 -10px;
			.col-12.col-xl-4 {
				padding: 10px;
				a {
					height: 55px;
					padding: 15px 10px;
					display: flex;
					flex-wrap: nowrap;
					color: #fff;
					background: $tcolor;
					text-align: center;
					align-items: center;
					border: 1px solid $tcolor;
					border-radius: 5px;
					@include transition-attr(all, .5s);

					span {
						width: calc(100% - 33px);
						font-size: 16px;
						line-height: 18px;
						text-align: center;
						color: #fff; }


					[class*='icon-'] {
						width: 33px;
						font-size: 28px;
						line-height: 28px;
						color: #fff; } }

				&:hover a {
					background: #fff;
					span, [class*='icon-'] {
						color: $tcolor; } } } }



		.row.tbl {
			margin: 50px -15px;
			table {
				width: 100%;
				background: #fff;

				tr {
					&:first-child td {
						padding: 10px;
						font-weight: bold;
						font-size: 18px;
						border: {
							top: 0;
							bottom: 4px solid $scolor; }

						p strong {
							font-weight: bold; } }


					td {
						padding: 5px;
						font-size: 16px;
						text-align: center !important;

						border: {
							left: 3px solid $gcolor;
							right: 3px solid $gcolor; }

						&:first-child {
							border-right: 0; }

						&:last-child {
							border-left: 0; }

						p {
							margin: 0 !important;
							text-align: center !important; } } } } }

		.row.buttons {

			.offset-lg-4 {
				margin: 0 33.333333% 0 0; }
			a {
				width: 100%;
				height: 50px;
				padding: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $fcolor;
				color: #fff;
				text-align: center;
				line-height: 1;
				border: {
					width: 0;
					radius: 0; }
				@include transition-attr(all, .3s);
				span {
					font-size: 16px;
					line-height: 16px;
					font-weight: 600;
					text-align: center;
					color: #fff;
					@include transition-attr(all, .3s); }

				.icon-lmp,
				.icon-plus {
					font-size: 20px;
					margin-right: 10px;
					color: #fff;
					@include transition-attr(all, .3s); }

				&:hover {
					background: $scolor;
					* {
						color: $fcolor; } } } } }


	#infographic {
		padding: 50px 0;
		background: #fff;
		.chars {
			justify-content: space-around;
			.char {
				margin: 50px 0;
				position: relative;
				cursor: pointer;

				&-descr {
					width: 200px;
					padding: 10px;
					position: absolute;
					bottom: 110px;
					right: 0;
					background: #fff;
					border: 1px solid $gcolor;
					color: $textgray;
					font-size: 16px;
					opacity: 0;
					@include transition-attr(opacity,.6s); }

				&-content {
					height: 100px;
					width: 100px;
					border-radius: 50%;
					background: $gcolor;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					@include transition-attr(all,.6s);

					p {
						margin: 0;
						font-size: 18px;
						line-height: 20px;
						direction: ltr;
						color: $textgray;
						span {
							width: 100%;
							display: block;
							text-align: center;
							font-size: 18px;
							line-height: 20px; } } }


				&:hover {
					.char-content {
						background: $textgray;
						* {
							color: $scolor; } }

					.char-descr {
						opacity: 1; } } } }


		.color-temper {
			align-items: center;
			justify-items: center;
			.col-md-2 p {
				margin: 0;
				padding-left: 30px;
				font-size: 18px;
				font-weight: bold; }

			.col-md-10 {
				text-align: center;
				img {
					width: 100%; } } } }


	#related-products {
		padding: 50px 0;
		background: #ebebeb;
		.hikashop_products_listing_main {
			h2 {
				display: none; }

			.related-slider {
				width: 100% !important;
				direction: ltr  !important;

				.slick-slide {
					float: left; } }

			.col-12.col-md-4 {
				.product-image {
					background: #fff;
					img {
						display: block;
						margin: 0 auto; } }

				.product-title {
					height: 190px;
					padding: 10px 15px;
					display: flex;
					background: #dfdfdf;
					flex: {
						wrap: nowrap;
						direction: row-reverse; }

					.left {
						width: 70%;
						.product-name {
							display: block;
							font-weight: bold; }
						* {
							color: $fcolor; }

						a:not(.product-name) {
							font-size: 16px;
							line-height: 18px; }

						a:nth-child(2) {
							font-weight: 800; } }

					.right {
						width: 30%;
						form a {
							span {
								display: block;
								text-align: center;
								color: $tcolor;
								font-size: 14px;
								&.icon {
									width: 30px;
									height: 30px;
									display: flex;
									margin: 5px auto 0;
									justify-content: center;
									align-items: center;
									border: {
										radius: 50%;
										color: $tcolor;
										width: 2px;
										style: solid; }
									@include transition-attr(border, .4s);
									.fas {
										color: $tcolor;
										font-size: 11px;
										@include transition-attr(color, .4s); } } }

							&:hover span .fas {
								font-size: 15px; } } } } } } }


	#mod-contact-us {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.3);
		display: none;
		align-items: center;
		justify-content: center;

		&.shw {
			display: flex; }

		.row.title {
			position: relative;

			.fas.fa-times {
				width: 40px;
				height: 40px;
				top: 0;
				left: 15px;
				position: absolute;
				background: $tcolor;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				cursor: pointer; }



			.col-12 > div {
				padding: 50px 90px 0;
				background: #fff;
				h2 {
					margin: 0;
					font-size: 24px;
					font-weight: 600; }

				p {
					margin: 0; } } }

		#fox-container-m115 {
			padding: 0 90px 50px;
			background: #fff;

			.fox-item-recaptcha div.controls .fox-item-recaptcha-display {
				margin: 0 0 0 auto;
				display: block; }

			.fox-item {
				width: 100%;
				margin: 0 0 15px 0;

				&.fox-item-submit-fields {
					margin: 0; }

				&#fox-m115-textfield5-box {
					display: none; }

				.controls {
					width: 100%;

					input:not([type="checkbox"]) {
						width: 100%;
						height: 40px;
						padding: 0 15px;
						border: 1px solid $fcolor;
						border-radius: 0; }


					.btn.btn-primary.submit-button {
						width: 100%;
						height: 40px;
						margin: 0;
						border: 0;
						background: $scolor;
						color: $fcolor;
						font-size: 18px;
						border-radius: 0;

						span {
							font-size: 20px;
							font-weight: bold;
							text-shadow: none; } }

					.checkbox {
						width: 100%;
						padding: 0 15px 0 0;
						text-align: right;
						input[type="checkbox"] {
							float: right;
							margin: 6px -15px 0 10px; } } } } } } }


.product.eng {
	.wrapper {
		background: none; }
	#top-image {
		height: 410px;
		background: {
			size: cover;
			position: center; }

		.row,
		.container {
			height: 100%; }

		.row {
			align-items: center; }

		p {
			margin: 10px 0 0;
			font-size: 36px;
			color: #fff;
			text-transform: uppercase; } }

	#main-content {
		padding: 10vh 0;
		background: {
			image: url("../images/product_bg.jpg");
			repeat: no-repeat; }

		h1 {
			font-size: 36px;
			color: $tcolor;
			text-transform: uppercase; }


		ul {
			list-style: none;
			li {
				display: flex;
				align-items: center;
				padding-left: 20px;

				&:before {
					content: '●';
					color: $tcolor;
					margin-left: -20px;
					margin-right: 15px; } } }

		hr {
			border-top-color: $fcolor;
			margin-left: 0;
			margin-right: auto;
			margin-bottom: 50px; }

		.under-construction {
			margin: 60px 0;

			.col-12 {
				border: 3px solid $fcolor;

				&:first-child {
					padding: 40px 50px 60px;
					border-bottom: 0;

					p {
						margin: 0; } }


				&:last-child {
					padding: 0;
					border-top: 0;

					a {
						display: block;
						max-width: 650px;
						padding: 10px 30px;
						margin: -20px auto -24px;
						background: $tcolor;
						color: #fff;
						position: relative;
						z-index: 1;
						font-weight: bold;
						text-align: center; } } } } } }

@media screen and (max-width: 1300px) {
	.product {
		#product .row.links .col-12.col-xl-4 {
			padding: 2px;
			a {
				height: 40px;
				padding: 5px; } }

		#infographic {
			.chars .char {
				margin: 15px 0; }

			.color-temper {
				margin-top: 30px; } } } }


@media screen and (max-width: 992px) {
	.product.eng #main-content {
		padding: 30px 0;

		hr {
			margin-bottom: 30px; }

		.under-construction {
			margin: 35px 0; } } }


@media screen and (max-width: 767px) {
	.product {
		#mod-contact-us .container {
			padding: 0;

			#fox-container-m115 {
				padding: 0 40px 20px; }

			.row.title .col-12>div {
				padding: 20px 40px 0; } }

		#product {
			padding: 20px 0;

			.container > .row {
				& > .col-12.col-md-6 {
					order: 2; }

				& > .col-12.col-md-5 {
					order: 1;
					margin-bottom: 25px; }

				&.tbl table {
					background: transparent;
					tr {
						display: block;
						padding-bottom: 10px;
						border-top: 1px solid $fcolor;

						&:first-child {
							display: none; }

						td {
							display: block;
							background: #fff;
							border: 0;
							border-bottom: 1px solid $gcolor;

							&:before {
								content: attr(data-content);
								display: block;
								background: $gcolor;
								margin-bottom: 5px; } } } }


				&.buttons {
					margin-top: 15px;
					.col-12.col-md-2.offset-lg-5 {
						margin-bottom: 10px; } } } } }

	.product.eng {
		#top-image {
			height: 260px;
			p {
				font-size: 24px; } }

		#main-content .under-construction .col-12 {
			&:last-child a {
				margin: 0; }

			&:first-child {
				padding: 30px; } } } }


@media screen and (max-width: 500px) {
	.product.eng #top-image {
		height: 200px; } }
