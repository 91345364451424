
@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-ExtraLight.eot');
	src: url('../fonts/Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-ExtraLight.woff2') format('woff2'),
	url('../fonts/Dosis-ExtraLight.woff') format('woff'),
	url('../fonts/Dosis-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-SemiBold.eot');
	src: url('../fonts/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-SemiBold.woff2') format('woff2'),
	url('../fonts/Dosis-SemiBold.woff') format('woff'),
	url('../fonts/Dosis-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-Bold.eot');
	src: url('../fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-Bold.woff2') format('woff2'),
	url('../fonts/Dosis-Bold.woff') format('woff'),
	url('../fonts/Dosis-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-ExtraBold.eot');
	src: url('../fonts/Dosis-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-ExtraBold.woff2') format('woff2'),
	url('../fonts/Dosis-ExtraBold.woff') format('woff'),
	url('../fonts/Dosis-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-Regular.eot');
	src: url('../fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-Regular.woff2') format('woff2'),
	url('../fonts/Dosis-Regular.woff') format('woff'),
	url('../fonts/Dosis-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-Medium.eot');
	src: url('../fonts/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-Medium.woff2') format('woff2'),
	url('../fonts/Dosis-Medium.woff') format('woff'),
	url('../fonts/Dosis-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/Dosis-Light.eot');
	src: url('../fonts/Dosis-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Dosis-Light.woff2') format('woff2'),
	url('../fonts/Dosis-Light.woff') format('woff'),
	url('../fonts/Dosis-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/*Hebrew*/
@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Light.eot');
	src: url('../fonts/Heebo-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Light.woff') format('woff'),
	url('../fonts/Heebo-Light.ttf') format('truetype'),
	url('../fonts/Heebo-Light.svg#Heebo-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Bold.eot');
	src: url('../fonts/Heebo-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Bold.woff') format('woff'),
	url('../fonts/Heebo-Bold.ttf') format('truetype'),
	url('../fonts/Heebo-Bold.svg#Heebo-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Black.eot');
	src: url('../fonts/Heebo-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Black.woff') format('woff'),
	url('../fonts/Heebo-Black.ttf') format('truetype'),
	url('../fonts/Heebo-Black.svg#Heebo-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-ExtraBold.eot');
	src: url('../fonts/Heebo-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-ExtraBold.woff') format('woff'),
	url('../fonts/Heebo-ExtraBold.ttf') format('truetype'),
	url('../fonts/Heebo-ExtraBold.svg#Heebo-ExtraBold') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Regular.eot');
	src: url('../fonts/Heebo-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Regular.woff') format('woff'),
	url('../fonts/Heebo-Regular.ttf') format('truetype'),
	url('../fonts/Heebo-Regular.svg#Heebo-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Thin.eot');
	src: url('../fonts/Heebo-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Thin.woff') format('woff'),
	url('../fonts/Heebo-Thin.ttf') format('truetype'),
	url('../fonts/Heebo-Thin.svg#Heebo-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Heebo';
	src: url('../fonts/Heebo-Medium.eot');
	src: url('../fonts/Heebo-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Heebo-Medium.woff') format('woff'),
	url('../fonts/Heebo-Medium.ttf') format('truetype'),
	url('../fonts/Heebo-Medium.svg#Heebo-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Glyphter';
	src: url('../fonts/Glyphter.eot');
	src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Glyphter.woff') format('woff'),
	url('../fonts/Glyphter.ttf') format('truetype'),
	url('../fonts/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Wed Mar 14 2018*/
@font-face {
	font-family: 'Glyphter';
	src: url('../fonts/Glyphter.eot');
	src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Glyphter.woff') format('woff'),
	url('../fonts/Glyphter.ttf') format('truetype'),
	url('../fonts/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Glyphter';
	src: url('../fonts/Glyphter.eot');
	src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Glyphter.woff') format('woff'),
	url('../fonts/Glyphter.ttf') format('truetype'),
	url('../fonts/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.icon-ytb:before{content:'\0041';}
.icon-ply:before{content:'\0042';}
.icon-glb:before{content:'\0043';}
.icon-lmp:before{content:'\0044';}
.icon-p1:before{content:'\0045';}
.icon-p2:before{content:'\0046';}
.icon-eltam:before{content:'\0047';}
.icon-p3:before{content:'\0048';}
.icon-p4:before{content:'\0049';}
.icon-p5:before{content:'\004a';}
.icon-p6:before{content:'\004b';}
.icon-p7:before{content:'\004c';}
.icon-plus:before{content:'\004d';}
.icon-search:before{content:'\004e';}
.icon-side1:before{content:'\004f';}
.icon-side2:before{content:'\0050';}
.icon-side3:before{content:'\0051';}
.icon-side4:before{content:'\0052';}
.icon-zoom:before{content:'\0053';}




@font-face {
	font-family: 'Glyphter2';
	src: url('../fonts/Glyphter2.eot');
	src: url('../fonts/Glyphter2.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Glyphter2.woff') format('woff'),
	url('../fonts/Glyphter2.ttf') format('truetype'),
	url('../fonts/Glyphter2.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class*='icon-cont']:before,
[class*='icon-cat']:before{
	display: inline-block;
	font-family: 'Glyphter2';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.icon-contactphone:before{content:'\0041';}
.icon-contactfax:before{content:'\0042';}
.icon-contactmail:before{content:'\0043';}
.icon-cat-1:before{content:'\0044';}
.icon-cat-2:before{content:'\0045';}
.icon-cat-3:before{content:'\0046';}
.icon-cat-4:before{content:'\0047';}
.icon-cat-5:before{content:'\0048';}
.icon-cat-6:before{content:'\0049';}
.icon-cat-7:before{content:'\004a';}
.icon-cat-8:before{content:'\004b';}
.icon-cat-9:before{content:'\004c';}
.icon-cat-10:before{content:'\004d';}
.icon-cat-11:before{content:'\004e';}
.icon-contactmapmarker:before{content:'\004f';}